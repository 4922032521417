import React from 'react';

import { NavHashLink as Link } from 'react-router-hash-link';
import FavoriteIcon from '@material-ui/icons/Favorite';

function Footer() {
    return (
        <section className="footer">
            <footer>
                <div className="main-container">
                    {/* Left */}
                    <div>
                        <h1 className="secondary-big-text heading ">
                            Notice
                        </h1>
                        <div style={{ fontFamily: 'GlacialIndifference', fontSize: '1em' }}>
                            <p>
                                This Is A Personal Online Portfolio Of Pari Ashwani.
                                All Of The Showcased Work Is Solely Owned By Her.
                            </p>
                        </div>
                    </div>
                    {/* Right */}
                    <div>
                        <h1 className="secondary-big-text heading ">Quick Links</h1>
                        <div style={{ fontFamily: 'GlacialIndifference', fontSize: '1em' }}>
                            <ul className="footer-links">
                                <li><Link to="/#about">
                                    About
                                </Link>
                                </li>
                                <li>
                                    <Link to="/#youtube">
                                        Youtube
                                </Link>
                                </li>
                                <li>
                                    <Link exact={true} to="/" >
                                        Home
                                </Link>

                                </li>
                                <li>
                                    <Link to="/contact" >
                                        Contact Me
                                </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <hr />
                    <p>
                        Copyright &copy; 2020 All Rights Reserved by <Link style={{ color: '#ffffff' }} to="/">Pari Ashwani</Link>.
                    </p>
                    <p style={{ display: "inline-flex" }}>
                        Made with&nbsp;<FavoriteIcon style={{ color: 'var(--accentColor)' }} fontSize="small" />&nbsp;by&nbsp;
                        <a style={{ color: '#ffffff' }}
                            href="https://www.linkedin.com/in/parteek-kumar/"
                            target="_blank" rel="noopener noreferrer" >Parteek Kumar
                        </a>.
                    </p>
                </div>
            </footer>
        </section>
    );
}

export default Footer;