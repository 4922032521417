import React from 'react';


import HorizontalScrolling from './HorizontalScrolling/HorizontalScrolling'
import MobileHorizontalScrolling from './MobileHorizontalScrolling/MobileHorizontalScrolling'
function SectionYoutube() {
    return (
        <section className="s2" id="youtube">
            <div className="main-container">
                <div >
                    <h1 className="section-heading text-center">
                        Recent Videos
                    </h1>
                    <div className="text-center">
                        <h3 className="heading help-text ">
                            Drag cards to view next.
                        </h3>
                    </div>

                    {/* <h3 className="heading" style={{ width:'100%', justifyContent: "flex-end" }}>
                        <span
                            className="scroll-menu-arrow "
                            onClick={() => {
                                let elementsArray = document.getElementsByClassName("arrow-next");
                                if (elementsArray && elementsArray.length > 0 && elementsArray[0])
                                    elementsArray[0].click();
                            }}>Next</span><br />
                        <span
                            className="scroll-menu-arrow "
                            onClick={() => {
                                let elementsArray = document.getElementsByClassName("arrow-prev");
                                if (elementsArray && elementsArray.length > 0 && elementsArray[0])
                                    elementsArray[0].click();
                            }}>Prev</span><br />
                    </h3> */}
                </div>

                <br />
                <div className="mobile-horizontal-scroll">
                    <MobileHorizontalScrolling />
                </div>
                <div className="horizontal-scroll">
                    <HorizontalScrolling />
                </div>
            </div>
        </section>
    );
}

export default SectionYoutube;