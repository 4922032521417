
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const ChannelLink = () => {
    return <div  style={{padding:'10px', minWidth: "194px", paddingTop: '100px'}}>
        <a className="watch-button"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UC20zLMifm2YcwrO5jTN9Y2Q"> <ArrowRightAltIcon/>Got to my Channel
        </a>
        <br /><br />
    </div>;
};

export default ChannelLink;