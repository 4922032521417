import React from 'react';
import './Home.css';
import SectionIntro from './SectionIntro.js';
import SectionYoutube from './SectionYoutube.js';
import SectionCollabs from './SectionCollabs.js'
import SectionAbout from './AboutProject/SectionAbout.js';

function Home() {

  return (
    <div className="">
      <SectionIntro />
      <SectionAbout />
      <SectionYoutube />
      <SectionCollabs/>
      
    </div>


  );
}

export default Home;
