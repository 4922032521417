import React from 'react';

import pari_about from '../images/pari-about.jpg'
import Stats from '../Stats';

import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
function SectionAbout() {
  register();
  return (
    <section className="section-about" id={'about'}>
      <div className="main-container"  >

        <div className="about-section-body" >

          <div className="left" >
            <img className="img-responsive rounded" src={pari_about} style={{ width: '80%' }} alt="preview" />
          </div>

          <div className="right rounded" >
            <div style={{}}>
              <h1 className="section-heading">I am&nbsp;
                                <span
                  className="txt-rotate"
                  data-period="2000"
                  data-rotate='[ "a Proud Indian!","a YouTuber.","a Medic.",  "a science lover.", "#ThatKYPGirl", "fun!","nerd!" ]'>a YouTuber!</span>
              </h1>
              <h2 className="text">I’m dedicated to create a bridge between cosmetic industry and the audience.</h2>
              <p className="about">
                <br />
                                With aim to serve the information, which could literally change someone’s life. It’s about the  science, research & facts.
                                I am always excited to interact with like minded people, let's discuss over&nbsp;
                                <a href="https://www.instagram.com/pari_ashwani" target="_blank" rel="noopener noreferrer" >Instagram</a>.
                            </p>
              <Stats />
              <div className="social-links">
                <a href="https://www.youtube.com/c/PariAshwani" target="_blank" rel="noopener noreferrer" className="ic-youtube" >
                  <YouTubeIcon style={{ fontSize: 40, color: '#FF0000' }} />
                </a>
                <a href="https://www.instagram.com/pari_ashwani" target="_blank" rel="noopener noreferrer" >
                  <InstagramIcon style={{ fontSize: 40, color: '#e4405f' }} />
                </a>
                <a aria-label="Twitter" href="https://twitter.com/Pari_Ashwani" target="_blank" rel="noopener noreferrer"  >
                  <TwitterIcon style={{ fontSize: 40, color: '#00acee' }} />
                </a>
                <a href="mailto:kumarpriyanka818@gmail.com" target="_blank" rel="noopener noreferrer">
                  <MailOutlineIcon style={{ fontSize: 40, color: '#D44638' }} />
                </a>

              </div>

            </div>



          </div>


        </div>
      </div>
    </section>
  );
}

function register() {

  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-rotate');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };
}



export default SectionAbout;



// function AboutProject() {
//     const about = (
//         <>
//             <p>
//                 I’m a first-generation Wellness and beauty influencer and I’m trying to
//                 collect enough karma points for a window-seat ticket to Heaven.
//                 <br />
//                 <br />
//                 While I’m alive though, I want to work on making little differences that hopefully add up to
//                 a lot of good by making people aware about science behind beauty on my <a target="_blank"
//                     rel="noopener noreferrer"
//                     href="https://www.youtube.com/c/PariAshwani">
//                     Youtube Channel
//                 </a> and volunteering with nonprofits.
//                 </p>
//         </>
//     );

//     return (
//         <section className="section-intro"  >
//             <div className="main-container">
//                 <div className="about-wrapper">
//                     <div className="right-column-about">
//                         <img className="img-responsive img-pari" src={pari_about} style={{ width: '90%' }} alt="Pari Cover" />

//                     </div>
//                     <div className="left-column-about about">
//                         <div className="text-center">
//                             <h1 className="section-heading ">About the Project</h1>
//                         </div>
//                     </div>
//                     <div className="left-column2-about">
//                         <div className="about">
//                             {about}
//                         </div>

//                         <div className="about">
//                             <h3>For Collaboration or Business Enquiries</h3>

//                         </div>

//                         {/* <div className="social-links">
//                             <a aria-label="Twitter" href="https://twitter.com/Pari_Ashwani" className="fa fa-twitter"  >
//                                 <span>
//                                     Twitter
//                                 </span>
//                             </a>
//                             <a href="https://www.youtube.com/c/PariAshwani" className="fa fa-youtube">
//                                 <span>youtube</span>
//                             </a>
//                             <a href="https://www.instagram.com/pari_ashwani" className="fa fa-instagram">
//                                 <span>instagram</span>
//                             </a>
//                         </div> */}
//                     </div>

//                 </div>


//             </div>

//         </section>
//     );
// }

// export default AboutProject;
