import React from 'react';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
    return images;
}

function BrandImage(props){
    return (
        <div>
            <img  loading="lazy"
            src={props.src} 
            alt={props.alt} />
        </div>
    );
}

function SectionCollabs() {
    const images = importAll(require.context('./images/brands/', false, /\.(png|jpe?g|svg|webp)$/));
    return (
        <>
        <section className="s3" id="section-collabs" style={{marginBottom: '0px'}}>
            <div className="main-container">
                <div className="text-center" style={{ }}>
                    <h1 className="section-heading ">
                        My Previous collaborations
                        
                    </h1>
                </div>
                <div className="brands-grid" style={{ paddingTop: '3em' }}>

                    {Object.keys(images).map((key_img) => (
                        <BrandImage key={key_img}  src={images[key_img]} alt={key_img} />
                    ))}

                </div>
                
            </div>
        </section>

        </>

    );
}

export default SectionCollabs;