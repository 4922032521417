import React from 'react';
import { Formik, Field, Form } from 'formik';
// import { TextField, Button } from "@material-ui/core";
import * as yup from 'yup';
import Recaptcha from 'react-recaptcha';
import database from '../firebase.js'


const validationSchema = yup.object({
    fullname: yup
        .string()
        .required(),
    email: yup
        .string()
        .required()
        .email(),
    subject: yup.string(),
    message: yup
        .string()
        .required(),
    recaptcha: yup.string().required()

});
let recaptchaInstance = undefined;
const ContactForm = () => {

    return (
        <div>
            <Formik
                validateOnChange={true}
                initialValues={{
                    fullname: '',
                    email: '',
                    subject: '',
                    message: '',
                    recaptcha: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting, resetForm, setStatus }) => {

                    const { recaptcha, ...datasnap } = data
                    datasnap.submissionDate = new Date();
                    setSubmitting(true);
                    database.collection("contact_responses")
                        .add(datasnap)
                        .then(function (docRef) {
                            setSubmitting(false);
                            resetForm(true);
                            if (recaptchaInstance)
                                recaptchaInstance.reset()
                            setStatus({ success: true })

                        })
                        .catch(function (error) {
                            setSubmitting(false);
                            if (recaptchaInstance)
                                recaptchaInstance.reset()
                            setStatus({ success: false })
                        });


                }}>

                {({ values, errors, touched, isSubmitting, setFieldValue, submitCount, status }) => (
                    <Form
                        className="form-elements">
                        <Field placeholder="Enter Your Name Here " className={` form-element ${errors.fullname && touched.fullname ? "error-input" : ""}`} name="fullname" type="input" />
                        {errors.fullname && touched.fullname && (
                            <div className="input-feedback">
                                {errors.fullname}
                            </div>
                        )}
                        <Field placeholder="abc@example.com" className={`form-element ${errors.email && touched.email ? "error-input" : ""}`} name="email" type="email" />
                        {errors.email && touched.email && (
                            <div className="input-feedback">
                                {errors.email}
                            </div>
                        )}
                        <Field placeholder="Let us talk about stars and bars" className="form-element" name="subject" type="input" />
                        <Field placeholder="Please explain your thoughts" className={`form-element ${errors.message && touched.message ? "error-input" : ""}`} name="message" type="input" as="textarea" />
                        {errors.message && touched.message && (
                            <div className="input-feedback">
                                {errors.message}
                            </div>
                        )}
                        <div className="cap" style={{ maxWidth: '95px' }}>
                            <Recaptcha
                                sitekey="6LdwOccZAAAAAOZhgPGr-gq-NCXbCSnBnkqtpgtt"
                                ref={e => recaptchaInstance = e}
                                render="explicit"
                                onloadCallback={() => { }}
                                verifyCallback={(response) => { setFieldValue("recaptcha", response) }}
                            />
                        </div>
                        {errors.recaptcha
                            && touched.recaptcha && (
                                <div className="input-feedback">
                                    {errors.recaptcha}

                                </div>
                            )}

                        <div className="form-response"> {(status) ? (status.success ?
                            (<p className="success-text">Your form has been sumitted</p>) :
                            (<p className="error-text">OOPS!! Error occurred. Please try again</p>)) : ""
                        }

                        </div>

                        <button className="contact-button" disabled={isSubmitting} type="submit">Submit</button>

                    </Form>
                )}
            </Formik>
        </div>

    );
    // return (
    //     <div className="form " >
    //         <div className="form-heading text-center">
    //             <h1 className="section-heading ">Ping Me</h1>
    //         </div>
    //         <div  >
    //             <form className="form-elements">
    //                 <label >Name</label>
    //                 <input type="text" id="username" required="required" placeholder="Enter Your Name Here  " />
    //                 <label >Email</label>
    //                 <input type="email" id="useremail" required="required" placeholder="abc@example.com" />
    //                 <label >Subject</label>
    //                 <input type="text" id="usersubject" placeholder="Let us talk about stars and bars" />
    //                 <label >Message</label>
    //                 <textarea id="usermessage" required="required" placeholder="Please explain your thoughts" />
    //                 <div className="text-center">
    //                     <button className="contact-button" type="submit" >Submit</button>
    //                 </div>
    //             </form>
    //         </div>
    //     </div>
    // );
}

export default ContactForm