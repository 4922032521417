import React from 'react';
import './SectionIntro.css';
import pari_logo from './images/intro-logo.svg';

import pari_cover from './images/pari-cover.jpg'
import { NavHashLink as Link } from 'react-router-hash-link';

function SectionIntro() {


    const about = (
        <>
            <p>
                <b>Namaste <span role="img" aria-label="Namaste" aria-labelledby="Namaste" >🙏 </span></b>
                Myself Priyanka Ashwani Kumar aka  Pari ashwani.
                 I’m running India's first Cosmetic Chemistry Channel on <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/c/PariAshwani">
                    Youtube
                </a> since 2018. I am a first-generation Wellness and beauty influencer and I’m trying to
                collect enough karma points for a window-seat ticket to Heaven.
                <br />
                <br />
                While I’m alive though, I want to work on making little differences that hopefully add up to
                a lot of good by making people aware about science behind beauty on my <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/c/PariAshwani">
                    Youtube Channel
                </a> and volunteering with nonprofits.
                </p>
        </>
    );

    return (
        <section className="section-intro"  >
            <div className="main-container">
                <div className="intro-wrapper">
                    <div className="left-column">
                        <img className="img-responsive img-pari" src={pari_cover} style={{ width: '85%' }} alt="Pari Cover" />

                    </div>
                    <div className="right-column">
                        <img className="img-responsive" src={pari_logo} style={{ width: '80%' }} alt="Pari Logo" />
                    </div>
                    <div className="right-column2">
                        <div className="about">
                            {about}
                        </div>

                        <div className="about">
                            <h3>For&nbsp;
                              <Link to="/#section-collabs" >
                                    Collabs.
                                </Link> or Business Enquiries</h3>
                            <Link to="/contact">
                                <button className="contact-button">Contact Us</button>
                            </Link>
                        </div>


                    </div>

                </div>


            </div>

        </section>
    );
}



export default SectionIntro;
