import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { XmlEntities as Entities } from 'html-entities';
import database from '../../firebase'
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const entities = new Entities();
function getSampleData() {
    const initial_data = [{
        videoId: 'iCb2U7_8XIU',
        title:
            'My 30+ Empties in 18 mins | Ep. 02 | Mini Reviews | HINDI | Pari Ashwani',
    },
    {
        title:
            'St. Ives Fresh Apricot Scrub | Know Your Product हिन्दी में | Pari Ashwani',
        videoId: '9Odgg5Zo7SE'
    },
    {
        videoId: '3H0zwVPon6I',
        title:
            'HAPDCO Herbals Marks Go Combo | Review | HINDI | Pari Ashwani',
    },
    {
        title:
            'Skincare, Medicated Formula &amp; Recommendation | HINDI | Pari Ashwani',
        videoId: 'UEQjKPrUOmg'
    },
    {
        videoId: 'VBIpuacT-tg',
        title:
            'Lipsticks Under 200/- | Mini Reviews | HINDI | Pari Ashwani'
    },
    {
        title:
            'Wet &amp; Wild Photo Focus Foundation | Know Your Product हिन्दी में | Pari Ashwani',
        videoId: 'fx_qLuW4OmI'
    },
    {
        videoId: 'BMaL5nvnMjo',
        title:
            'Everteen Intimate Washes | All 4 Variants | Review | HINDI | Pari Ashwani'
    },
    {
        videoId: 'XB9eHJeFKOk',
        title:
            'क्यों नहीं बताते इन ingredients के बारे में? | Part 2 | Let&#39;s Talk About | हिन्दी में | Pari Ashwani'
    },
    {
        videoId: 'Ev92-mn2o_8',
        title: 'My Empties | Ep. 01 | Mini Reviews | HINDI | Pari Ashwani',
    },
    {
        videoId: 'AFFysstB60g',
        title:
            'Nivea Soft Light Moisturizer | Know Your Product हिन्दी में | Pari Ashwani'
    }];
    return initial_data;
}

let list = getSampleData();

const MenuItem = (props) => {

    const { text, selected, videoId } = props;
    const src_img = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    const video_link = `https://www.youtube.com/watch?v=${videoId}`;
    return <div className={`video-card ${selected ? "active" : ""}`} >
        <div className="text-center">
            <img src={src_img} 
                alt="default"
                className="img-responsive" />
        </div>

        <div style={{ textAlign: 'left' }}>
            <h4 className="text video-text" >{text}</h4>
        </div>
        <div
            className="text-center vid-button-pre">

            <a className="watch-button"
                target="_blank"
                rel="noopener noreferrer"
                href={video_link}><span><PlayArrowOutlinedIcon /></span> <span>Watch Video</span> 

            </a>
            <br /><br />
        </div>
    </div>;
};
const ChannelLink = (props) => {
    const { selected } = props;
    return <div className={`${selected ? "active" : ""}`}  style={{padding:'10px'}}>
        <a className="watch-button"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UC20zLMifm2YcwrO5jTN9Y2Q"> <ArrowRightAltIcon/>Got to my Channel
        </a>
        <br /><br />
    </div>;
};




export const Menu = (list, selected) => {
    const returnValue = list.map(el => {
        const { videoId, title } = el;
        return <MenuItem text={title} videoId={videoId} key={videoId} selected={selected} />;
    });
    returnValue.push(<ChannelLink key={30} selected={selected} />)
    return returnValue;
}


const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
};

Arrow.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string
};

export const ArrowLeft = Arrow({ text: <pre>&#8249;</pre>, className: "arrow-prev" });
export const ArrowRight = Arrow({ text: <pre>&#8250;</pre>, className: "arrow-next" });

class HorizontalScrolling extends Component {
    state = {
        alignCenter: true,
        clickWhenDrag: true,
        dragging: true,
        hideArrows: true,
        hideSingleArrow: true,
        itemsCount: list.length,
        scrollToSelected: true,
        selected: "item1",
        translate: 0,
        transition: 0.2,
        wheel: false
    };

    getVideoMetaAsync = () => {
        database.collection('video_metadata').orderBy('publishedAt','desc').limit(10).onSnapshot(snapshot => {
            if (snapshot && snapshot.docs) {
               list= snapshot.docs.map(doc => {
                const val=doc.data();
                if(val && val.title){
                    val.title = entities.decode(val.title);
                }
                return val;
                });
            }
            if(list && list.length>0){
                this.menuItems = Menu(list, list.slice(-1)[0].name);
                this.setState({
                    itemsCount: list.length,
                    selected: this.state.selected
                });
            } 
        }
        );

        // let response = await fetch(`https://www.googleapis.com/youtube/v3/search?part=id,snippet&channelId=UC20zLMifm2YcwrO5jTN9Y2Q&maxResults=10&order=date&key=AIzaSyDGzjyVvTz8txncuvPBWE03Cw6kg0xgE_I`);
        // let data = await response.json()
        // return data;

    }


    constructor(props) {
        super(props);
        this.menu = null;
        this.getVideoMetaAsync();
        // .then(data => {
        //     if (data && data.items) {
        //         list = data.items.filter(function (item) {
        //             if (item.id && item.id.videoId && item.snippet && item.snippet.title) {
        //                 return true; // skip
        //             }
        //             return false;
        //         }).map((item) => {
        //             return { videoId: item.id.videoId, title: entities.decode(item.snippet.title) };
        //         });

        //     }
        //     this.menuItems = Menu(list, list.slice(-1)[0].name);
        //     this.setState({
        //         itemsCount: list.length,
        //         selected: this.state.selected
        //     });

        // });
        this.menuItems = Menu(list.slice(0, list.length), this.state.selected);
    }



    onLastItemVisible = () => {
        // const newItems = Array(5)
        //     .fill(1)
        //     .map((el, ind) => ({ name: `item${list.length + ind + 1}` }));
        // list = list.concat(newItems);
        // this.menuItems = Menu(list, list.slice(-1)[0].name);
        // this.setState({
        //     itemsCount: list.length,
        //     selected: this.state.selected
        // });
    };

    onUpdate = ({ translate }) => {
        this.setState({ translate });
    };

    onSelect = key => {
        this.setState({ selected: key });
    };

    componentDidUpdate(prevProps, prevState) {
        const { alignCenter } = prevState;
        const { alignCenter: alignCenterNew } = this.state;
        if (alignCenter !== alignCenterNew) {
            this.menu.setInitial();
        }
    }

    setItemsCount = ev => {
        const { itemsCount = list.length, selected } = this.state;
        const val = +ev.target.value;
        const itemsCountNew =
            !isNaN(val) && val <= list.length && val >= 0
                ? +ev.target.value
                : list.length;
        const itemsCountChanged = itemsCount !== itemsCountNew;

        if (itemsCountChanged) {
            this.menuItems = Menu(list.slice(0, itemsCountNew), selected);
            this.setState({
                itemsCount: itemsCountNew
            });
        }
    };

    setSelected = ev => {
        const { value } = ev.target;
        this.setState({ selected: String(value) });
    };

    render() {
        const {
            clickWhenDrag,
            hideArrows,
            dragging,
            hideSingleArrow,
            scrollToSelected,
            selected,
            transition,
            wheel
        } = this.state;

        const menu = this.menuItems;


        return (
            <div className="projects">
                <ScrollMenu
                    alignCenter={false}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    clickWhenDrag={clickWhenDrag}
                    data={menu}
                    dragging={dragging}
                    hideArrows={hideArrows}
                    hideSingleArrow={hideSingleArrow}
                    onLastItemVisible={this.onLastItemVisible}
                    onSelect={this.onSelect}
                    onUpdate={this.onUpdate}
                    ref={el => (this.menu = el)}
                    scrollToSelected={scrollToSelected}
                    selected={selected}
                    transition={+transition}
                    translate={0}
                    wheel={wheel}
                    scrollBy={1}
                />
            </div>
        );
    }
}

export default HorizontalScrolling;
