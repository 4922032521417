import React from 'react';
import Home from './Home/Home.js';
import Navigation from './Nav/Navigation.js';
import Contact from './Contact/Contact.js';
import Footer from './Home/Footer/Footer.js'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {

  return (
    <Router >
      <Navigation />
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/contact" exact={true} component={Contact} />
      </Switch>
    <Footer/>

    </Router>
  );
}

export default App;
