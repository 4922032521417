import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem.js";
import database from "../../firebase.js";
import "./MobileHorizontalScrolling.css";
import ChannelLink from "./ChannelLink.js";

function MobileHorizontalScrolling() {
  const [list, setList] = useState([]);
  useEffect(() => {
    const unsub = database
      .collection("video_metadata")
      .orderBy("publishedAt", "desc")
      .limit(10)
      .onSnapshot((snapshot) => {
        if (snapshot && snapshot.docs) {
          setList(snapshot.docs.map((doc) => doc.data()));
        }
      });
    return unsub;
  }, []);

  // const actionPrev = () => {
  //     const menu_div = document.querySelector(".horizontal-scroll-menu");
  //     let distance = (menu_div && menu_div.offsetWidth > 0) ? menu_div.offsetWidth - 0.1 * (menu_div.offsetWidth) : 0;

  //     sideScroll(menu_div, 'left', 5, distance, 15);

  // }
  // const actionNext = () => {
  //     const menu_div = document.querySelector(".horizontal-scroll-menu");
  //     let distance = (menu_div && menu_div.offsetWidth > 0) ? menu_div.offsetWidth - 0.1 * (menu_div.offsetWidth) : 0;
  //     sideScroll(menu_div, 'right', 5, distance, 15);
  //     // menu_div.scrollLeft += width;
  // }

  return (
    <div style={{ display: "flex" }}>
      {/* <Arrow text={(<pre>&#8249;</pre>)} className="arrow-prev" onClick={actionPrev}  /> */}
      <div className="horizontal-scroll-menu" id="horizontal-menu">
        {list.map((ele) => (
          <MenuItem key={ele.videoId} list_item={ele} />
        ))}
        <ChannelLink />
      </div>
      {/* <Arrow text={(<pre>&#8250;</pre>)} className="arrow-next" onClick={actionNext}  /> */}
    </div>
  );
}

// var button = document.getElementById('slide');
// button.onclick = function () {
//     var container = document.getElementById('container');
//     sideScroll(container,'right',25,100,10);
// };

// var back = document.getElementById('slideBack');
// back.onclick = function () {
//     var container = document.getElementById('container');
//     sideScroll(container,'left',25,100,10);
// };

// function sideScroll(element, direction, speed, distance, step) {
//   let scrollAmount = 0;
//   var slideTimer = setInterval(function () {
//     if (direction === "left") {
//       element.scrollLeft -= step;
//     } else {
//       element.scrollLeft += step;
//     }
//     scrollAmount += step;
//     if (scrollAmount >= distance) {
//       window.clearInterval(slideTimer);
//     }
//   }, speed);
// }

export default MobileHorizontalScrolling;
