import React from 'react';
import { XmlEntities as Entities } from 'html-entities';

import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
const entities = new Entities();

const MenuItem = (props) => {

    const { title, videoId } = props.list_item;
    const src_img = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    const video_link = `https://www.youtube.com/watch?v=${videoId}`;
    return <div className="video-card" >
        <div className="text-center">
            <img src={src_img}
                alt="default"
                className="img-responsive" />
        </div>

        <div style={{ textAlign: 'left' }}>
            <h4 className="text video-text" >{entities.decode(title)}</h4>
        </div>
        <div
            className="text-center vid-button-pre">

            <a className="watch-button"
                target="_blank"
                rel="noopener noreferrer"
                href={video_link}><span><PlayArrowOutlinedIcon /></span> <span>Watch Video</span>

            </a>
            <br /><br />
        </div>
    </div>;
};



export default MenuItem;