import React, { useEffect, useState } from 'react';

import database from '../firebase.js'

export default function Stats() {

    const [stats, setStats] = useState();
    useEffect(() => {
        const unsub = database.collection('stats').orderBy('count', 'desc').onSnapshot(snapshot => {
            if (snapshot && !snapshot.empty && snapshot.docs) {
                const list = snapshot.docs.map(doc => doc.data());
                setStats(list);
            }
        });
        return unsub;
    }, [])
    return (
        <section style={{ paddingTop: '30px' }}>
            <div className="numbers-grid">
                {
                    stats && stats.map(stat => (
                        <div key={stat.label} className="text-center">
                            <h1 className="secondary-big-text heading " style={{ fontSize: '2em' }}>
                                {stat.count}
                            </h1>
                            <span className=" heading ">
                                {stat.label}
                            </span>
                        </div>
                    ))
                }

            </div>

        </section>
    );
}