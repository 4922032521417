import React from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import './Navigation.css'
function Navigation() {

    return (

        <nav className="main-container">
            <div className="links-wrapper" >
                <div className="links">
                    <Link to="/#youtube">
                        Youtube
                        </Link>
                    <Link exact={true} to="/" >
                        Home
                        </Link>
                    <Link to="/contact" >
                        Contact Me
                        </Link>
                    <Link to="/#about">
                        About
                        </Link>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;