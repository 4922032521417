import * as firebase from "firebase/app"
import 'firebase/database';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB01pQD3er13rOuRWYYNxAJ01GzALZCjLY",
  authDomain: "pari-ashwani.firebaseapp.com",
  databaseURL: "https://pari-ashwani.firebaseio.com",
  projectId: "pari-ashwani",
  storageBucket: "pari-ashwani.appspot.com",
  messagingSenderId: "665537204196",
  appId: "1:665537204196:web:af524d291e86af242001f1",
  measurementId: "G-7NNL40R1S2"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebaseApp.firestore();

export default database;