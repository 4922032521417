import React, { useEffect } from 'react'
import pari_logo from "./ic-pari-light.svg"
import ContactForm from './ContactForm'

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <section style={{ paddingTop: '5vh' }} className="contact-section">
            <div className="main-container contact-section-wrapper" >
                <div className="contact-left-div">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={pari_logo} style={{ width: '70%' }} className="img-responsive " alt="logo" />
                    </div>
                    <div style={{ padding: '30px' }}>
                        <h1 className="section-heading ">Let’s talk</h1>
                        <h4 className="about" style={{ textAlign: "left" }}>
                            Here are some suggested talking points should you wish to connect with me:
                            <ul>
                                <li>Brand Collaborations</li>
                                <li>Supporting my skin care community</li>
                                <li>Defeating PCOD</li>
                                <li>My Journey with Skin Care and Cosmetic Chemistry</li>
                                <li>My mission </li>
                                <li>Changing the world </li>
                                <li>Volunteering opportunities</li>
                            </ul>
                        </h4>
                    </div>

                </div>
                <div className="contact-right-div">
                    <div className="form " >
                        <div className="form-heading text-center">
                            <h1 className="section-heading ">Ping Me</h1>
                        </div>
                        <ContactForm />
                    </div>
                </div>

            </div>
        </section>
    );
}
export default Contact;


